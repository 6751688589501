import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../images/logo.png"
import GregSrc from "../images/greg.jpg"
import SteveWSrc from "../images/stevew.jpg"
import JeddSrc from "../images/jedd.jpg"
import SteveFSrc from "../images/stevef.jpg"

const Acs = () => {
  const data = useStaticQuery(graphql`
    query {
      gcms {
        beers {
          name
          abv
          image {
            url
          }
          slug
          style
          description
        }
      }
    }
  `)

  const {
    gcms: { beers },
  } = data

  return (
    <Layout pageName="acs" header={false} footer={false}>
      <SEO title="Rotation Beer Company" />
      <div>
        <img src={Logo} alt="Rotation Beer" width="10%" />
      </div>
      <div style={{ float: "right", width: "25%" }}>
        <div className="gallery">
          <ul style={{ display: "block" }}>
            <li className="grayscale">
              <img src={GregSrc} alt="Greg" />
              <span className="top-title" style={{ display: "block" }}>
                Greg
              </span>
              <span className="">greg@rotation-beer.com</span>
            </li>
            <li className="grayscale">
              <img src={SteveWSrc} alt="Steve" />
              <span className="top-title" style={{ display: "block" }}>
                Steve
              </span>
              <span className="">steve@rotation-beer.com</span>
            </li>
            <li className="grayscale">
              <img src={JeddSrc} alt="Jedd" />
              <span className="top-title" style={{ display: "block" }}>
                Jedd
              </span>
              <span className="">jedd@rotation-beer.com</span>
            </li>
            <li className="grayscale">
              <img src={SteveFSrc} alt="Steve" />
              <span className="top-title" style={{ display: "block" }}>
                Steve
              </span>
              <span className="">steve.fischer@rotation-beer.com</span>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="gallery gallery-list"
        style={{ float: "left", width: "70%" }}
      >
        <ul style={{ display: "block", textAlign: "left" }}>
          {beers.map(beer => (
            <li style={{ marginBottom: 60 }}>
              <img
                src={beer.image.url}
                alt={beer.name}
                style={{
                  width: "20%",
                  opacity: 1,
                  float: "left",
                  marginRight: 10,
                }}
              />
              <span
                className="bottom-title"
                style={{ fontSize: "2vw", height: 50, display: "block" }}
              >
                {beer.name}
              </span>
              <span className="top-title" style={{ display: "block" }}>
                {beer.style} - {beer.abv}%
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  fontFamily: "arial",
                  textTransform: "none",
                  display: "block",
                  marginTop: 10,
                  fontSize: "1.2em",
                }}
              >
                {beer.description}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default Acs
